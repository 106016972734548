<template>
    <el-popover
        placement="bottom-start"
        width="100%"
        trigger="click"
        @show="showSelect"
        @hide="hideSelect"
        :popper-class="`el-select-popover ${popoverClass}`"
        v-model="visible"
        :disabled="!arrowShow">
        <template slot="reference">
            <div
                class="title-slot"
                :class="[arrowShow ? 'arrow-show-popover' : '', iconStatus === 1 ? 'title-selected' : '']"
            >
                {{ title }}
                <span
                    v-show="arrowShow"
                    :class="iconStatus === 1 ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "></span>
            </div>
        </template>

        <div class="select-popover" v-if="arrowShow">
            <div class="select-popover-content">
                <div class="select-popover-input">
                    <input class="select-popover-search" :class="{ 'only-search' : type === 'SearchOnly' }" v-if="enableSearch" v-model="keywords" type="text" :placeholder="placeholder">
                </div>
                <div class="select-popover-list">
                    <div class="select-popover-item"
                        v-for="(item, index) in selectItem"
                        :key="index"
                        v-show="!keywords || (keywords && item.label && item.label.indexOf(keywords) !== -1)"
                        :class="{'disable-select' : disableSelect && !item.isSelect}"
                        @click="selectItemHandle($event,item)"
                        >

                        <div class="text-ellipsis">
                            <input hidden v-model="item.isSelect" type="checkbox">
                            <span class="select-popover-item-checkbox">
                                
                            </span>
                            <div :title="item.label" class="select-popover-item-content text-ellipsis">
                                {{ item.label }}
                            </div>
                            <span v-if="enableCount" class="select-popover-item-count">
                                ({{ item.count }})
                            </span>
                        </div>

                    </div>
                    <!--<div class="default">-->
                        <!--<img src="" alt="">-->
                        <!--<span>未查询到结果</span>-->
                    <!--</div>-->
                </div>
            </div>

            <div class="btn-container" v-if="enableBtn">
                <el-button class="btn-reset" size="mini" @click="resetSelect">
                    重置
                </el-button>
                <el-button class="btn-confirm" type="primary" size="mini" @click="confirm">
                    确定
                </el-button>
            </div>
        </div>
    </el-popover>
</template>

<script>

    export default {
        name: "select-popover",
        componentName: "select-popover",
        data (){
            return {
                selectResult: [],
                keywords: '',
                iconStatus: 0,
                visible: false,
                isEmit: false,
            }
        },
        props: {
            type: {
                default: 'MultipleCheck',
                type: String
            },
            placeholder: {
                //输入框占用提示语
                default: '输入关键字',
                type: String,
            },
            enableSearch: {
                //是否有输入框
                default : false,
                type: Boolean,
            },
            enableBtn:{
                //是否有底部按钮
                default: true,
                type: Boolean
            },
            enableEmit: {
                //是否点击选择框外自动搜索
                default: false,
                type: Boolean
            },
            maxSelect: {
                //最多选择数量
                default: Infinity,
                type : Number
            },
            selectItem :{
                //选择项数组
                default: function() {
                    return [{
                        label: '请选择',
                        isSelect: false,
                        count: 0,
                    }];
                },
                type: Array,
            },
            title: {
                default: '标题',
                type: String,
            },
            label: {
                default: '',
                type: String,
            },
            enableCount : {
                default: false,
                type: Boolean,
            },
            defaultSelectedItems:{
                type: Array,
                default: function(){
                    return [];
                }
            },
            popoverClass: '',
            confirmCallBackFunction: () => {

            },
            resetCallBackFunction: () => {

            }
        },
        computed : {
            disableSelect (){
                return this.selectResult.length >= this.$props.maxSelect;
            },
            arrowShow (){
                switch(this.type){
                    case 'SearchOnly':
                        return true;
                    default:
                        return this.selectItem.length > 0;
                }
            },
        },
        created (){

        },
        watch: {
            defaultSelectedItems(val,oldVal){
                if(val.length == 0){
                    return;
                }
                this.handleDefaultSelectedItems();
            }
        },
        methods : {
            selectItemHandle (e, item){
                this.cleanSelectResult();
                if(item.isSelect){
                    item.isSelect = false;
                    for(let key in this.selectResult){
                        if(this.selectResult[key] === item){
                            this.selectResult.splice(key, 1)
                        }
                    }
                } else {
                    if(this.selectResult.length < this.$props.maxSelect){
                        for(let key in this.selectResult){
                            if(this.selectResult[key] === item){
                                return false;
                            }
                        }
                        item.isSelect = true;
                        this.selectResult.push(item);
                    } else {
                        return shortTips(`最多只能选择${this.$props.maxSelect}个。`)
                    }
                }
            },
            cleanSelectResult (){
                this.selectResult = [];

                for(let key = 0; key < this.$props.selectItem.length; key ++){
                    if(this.$props.selectItem[key].isSelect){
                        this.selectResult.push(this.$props.selectItem[key])
                    }
                }
            },
            showSelect(){
                this.selectResult = this.selectItem.filter((item,index) => {
                    return item.isSelect === true;
                });
                this.iconStatus = 1;
            },
            hideSelect(){
                this.iconStatus = 0;
                if(this.enableEmit && !this.isEmit){
                    return this.emitChange();
                }
                this.keywords = '';
                this.isEmit = this.enableEmit ? false : null;
            },
            resetSelect (){
                for(let key in this.selectItem){
                    this.selectItem[key].isSelect = false;
                }
                this.selectResult = [],this.keywords = '';

                return this.emitChange();
            },
            confirm (){
                this.isEmit = this.enableEmit ? true : null;
                this.visible = false;
                this.emitChange();
            },
            emitChange (){
                let emitValue = '';
                this.cleanSelectResult();
                this.selectResult = this.selectResult.filter((item,index) => {
                    return item.isSelect === true;
                })
                switch(this.type){
                    case 'SearchOnly':
                        if(!this.keywords){
                            emitValue = [];
                        } else {
                            emitValue = [{
                                label: this.keywords,
                                isSelect: false,
                            }];
                        }
                        break;
                    default:
                        emitValue = this.selectResult;
                        break;
                }
                this.visible = false,this.keywords = '';
                return this.$emit('selectChange',this.$props.label,emitValue);
            },
            handleDefaultSelectedItems(){
                this.defaultSelectedItems.map(item=>{
                    let _index = this.selectItem.findIndex(element=>element.label===item.label);
                    if(_index!=-1){
                        this.selectItem[_index].isSelect = true;
                        this.selectResult.push(this.selectItem[_index]);
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "#/css/scss/variables.scss";

    .icon-up{
        display: inline-block;
        transform: rotate(-90deg);
    }

    .icon-down{
        display: inline-block;
        transform: rotate(90deg);
    }

    .text-ellipsis{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .title-slot{
        padding: 0 0 0 10px;
        outline: none;
        line-height: unset;
        width: 100%;

        .el-icon-arrow-up,.el-icon-arrow-down{
            margin-left: 5px;
        }

        &.arrow-show-popover {
            &:hover{
                color: $primary;
            }
        }
        &.title-selected{
            color: $primary;
        }
    }

    .select-popover{
        width: 100%;
        min-width: 200px;
        padding-top: 0;
        background-color: #fff;
        box-shadow: 0 0 8px 0 rgba(#000, .2);
        border-radius: 4px;

        &-search{
            margin-bottom: 16px;
            padding: 4px 16px;
            width: 100%;
            height: 28px;
            line-height: 28px;
            background-color: #F8F8F8;
            border-radius: 4px;
            color: #999999;
            border: 1px solid #DDDDDD;

            &:focus{
                border-color: $primary;
                color: #666666;
                outline: none;
                // &::placeholder {
                //     color: #666;
                // }
            }

            &.only-search{
                margin-bottom: 0;
            }
        }

        .select-popover-content{
            padding: 18px;
            padding-right: 0;
        }

        .select-popover-input {
            padding-right: 18px;
        }

        .select-popover-list{
            max-width: 220px;
            max-height: 261px;
            overflow-y: auto;

            .select-popover-item{
                margin-bottom: 19px;
                line-height: 16px;
                cursor: pointer;
                &:last-child{
                    margin-bottom: 0;
                }

                &.disable-select{
                    cursor: not-allowed;
                    color: #999999;

                    .select-popover-item-checkbox{
                        border-color: #bbb !important;
                    }
                }

                .select-popover-item-checkbox{
                    display: inline-block;
                    float: left;
                    margin-right: 6px;
                    width: 16px;
                    height: 16px;
                    border: 1px solid #999999;
                    border-radius: 2px;
                }

                .select-popover-item-count{
                    display: inline-block;
                    vertical-align: top;
                }

                .select-popover-item-content{
                    max-width: 130px;
                    display: inline-block;
                }

                input:checked + .select-popover-item-checkbox{
                    border-color: $primary;

                    &:after{
                        position: relative;
                        display: block;
                        content: '';
                        top: 2px;
                        left: 2px;
                        width: 10px;
                        height: 6px;
                        transform: rotate(-45deg);
                        border-left: 1px solid;
                        border-bottom: 1px solid;
                        border-color: $primary;
                    }
                }
            }
        }

        .default{
            display: table;
            margin: auto;
            padding: 73px 0 97px 0;
            color: #666666;

            span,img{
                display: table;
                margin: auto;
            }
        }

        .btn-container{
            padding: 6px 18px;
            background-color: #F8F8F8;
            text-align: right;

            .btn-reset,.btn-confirm{
                padding: 0;
                min-width: unset;
                width: 72px;
                height: 28px;
                line-height: 26px;
                border-radius: 4px;
            }

            .btn-reset{
                color: #999999;
                background: #F8F8F8;
                border: 1px solid #DDDDDD;
                &:hover{
                    background: #EEEEEE;
                }
            }
        }
    }
</style>

<style lang="scss">
    .el-popover.el-select-popover{
        padding: 0;
        border: none;
        box-shadow: none;
        .popper__arrow {
            display: none;
        }
    }
</style>
